import {localStorageGetFavourites, localStorageSetFavourites} from "@/utils/localStorage";

export default {
  state: {
    username: null,
    sessionToken: null,
    favourites: [],
  },
  mutations: {
    username: (state, value) => {
      state.username = value;

      state.favourites = localStorageGetFavourites(value);
    },
    sessionToken: (state, value) => {
      state.sessionToken = value;
    },
    favourites: (state, value) => {
      state.favourites = value;
    },
    addFavourite: (state, { eventName, dataPoint }) => {
      state.favourites.push({
        dataPointName: dataPoint.data_point_name,
        eventName: eventName,
      });

      localStorageSetFavourites(state.username, state.favourites);
    },
    removeFavourite: (state, { eventName, dataPoint }) => {
      for (let i = state.favourites.length - 1; i >= 0; i--) {
        const favourite = state.favourites[i];

        if (favourite.eventName === eventName && favourite.dataPointName === dataPoint.data_point_name) {
          state.favourites.splice(i, 1);
        }
      }

      localStorageSetFavourites(state.username, state.favourites);
    },
  },
  getters: {
    username(state) {
      return state.username;
    },
    sessionToken (state) {
      return state.sessionToken;
    },
    favourites(state) {
      return state.favourites;
    },
  },
}
