<template>
  <div class="event">
    <h1 :title="eventTitle">Favourites ({{ favourites.length }})</h1>

    <div class="loading" v-if="loading">
      <span class="spinner-border" aria-hidden="true"></span>
    </div>
    <div class="loading" v-else-if="loadingError">
      <div class="row mt-4">
        <div class="col error">
          {{ loadingError }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button class="btn btn-danger" @click="retryLoading">Try again</button>
        </div>
      </div>
    </div>
    <div class="statistics" v-else>
      <div class="sub-statistics" v-if="dataPointTimeMinimum || dataPointTimeMaximum">
        <strong>First data point</strong>: {{ dataPointTimeMinimumFormatted }}<br />
        <strong>Last data point</strong>: {{ dataPointTimeMaximumFormatted }}<br />
        <strong>Total data points</strong>: {{ dataPointCount }}
      </div>
      <div class="filters">
        <div class="row">
          <div class="col">
            <Datepicker
              v-model="dateRange"
              :disabled="allTime"
              range
              :preset-ranges="datePresetRanges"
              :preset-dates="datePresetRanges"
              position="left"
              multi-calendars
              multi-static
              :enable-time-picker="false"
              @update:model-value="onDateChanged"
            />
          </div>
          <div class="col">
            <label class="all-time-checkbox"><input type="checkbox" v-model="allTime" /> Show data from beginning to now</label>
          </div>
        </div>
      </div>

      <data-point
        v-for="dataPoint in dataPoints"
        :data-point="dataPoint"
        :event-name="dataPoint.event_name"
        :time-range="timeRangeCalculated"
        :show-title-event-name="true"
      />
    </div>
  </div>
</template>

<script>

import DataChart from "@/components/chart/DataChart.vue";
import DataPoint from "@/components/chart/DataPoint.vue";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { format, parseISO, endOfMonth, startOfMonth, formatISO, subDays, startOfISOWeek, endOfISOWeek, endOfDay, startOfDay, subMonths, subYears, min, max } from 'date-fns';
import {mapGetters} from "vuex";

export default {
  name: 'DashboardFavouriteView',
  components: {DataPoint, DataChart, Datepicker},
  data() {
    return {
      eventId: null,
      dataPointTimeMinimum: null,
      dataPointTimeMaximum: null,

      loading: true,
      data: null,

      dataPoints: [],
      dataPointCount: 0,

      dateRange: null,
      datePresetRanges: null,
      timeRange: null,

      loadingError: '',

      allTime: true,
    };
  },
  watch: {
    allTime() {
      this.updateUrlQuery();
    },
    favourites: {
      handler() {
        for (let i = this.dataPoints.length - 1; i >= 0; i--) {
          const dataPoint = this.dataPoints[i];

          let foundFavourite = false;

          for (const favourite of this.favourites) {
            if (dataPoint.event_name === favourite.eventName && dataPoint.data_point_name === favourite.dataPointName) {
              foundFavourite = true;
            }
          }

          if (!foundFavourite) {
            this.dataPoints.splice(i, 1);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['favourites']),
    favouriteCount() {
      return this.favourites ? this.favourites.length > 0 : 0;
    },
    timeRangeCalculated() {
      if (this.allTime) {
        return 'allTime';
      } else {
        return this.timeRange;
      }
    },
    eventTitle() {
      return this.eventName;
    },
    dataPointTimeMinimumFormatted() {
      console.log('dataPointTimeMinimum', this.dataPointTimeMinimum);

      if (!this.dataPointTimeMinimum) {
        return '(never)';
      }

      try {
        return formatISO(this.dataPointTimeMinimum);
      } catch (e) {
        return '(never)';
      }
    },
    dataPointTimeMaximumFormatted() {
      console.log('dataPointTimeMaximum', this.dataPointTimeMaximum);

      if (!this.dataPointTimeMaximum) {
        return '(never)';
      }

      try {
        return formatISO(this.dataPointTimeMaximum);
      } catch (e) {
        return '(never)';
      }
    }
  },
  methods: {
    recalculateDateRange() {
      const datePresetRanges = [
        {
          label: 'Today',
          range: [new Date(), new Date()]
        },
        {
          label: 'This week',
          range: [startOfISOWeek(new Date()), endOfISOWeek(new Date())]
        },
        {
          label: 'This month',
          range: [startOfMonth(new Date()), endOfMonth(new Date())]
        },
        {
          label: 'Last 7 days',
          range: [subDays(new Date(), 7), new Date()],
        },
        {
          label: 'Last 30 days',
          range: [subDays(new Date(), 30), new Date()],
        },
        {
          label: 'Last 3 months',
          range: [subMonths(new Date(), 3), new Date()],
        },
        {
          label: 'Last 6 months',
          range: [subMonths(new Date(), 6), new Date()],
        },
        {
          label: 'Last 1 year',
          range: [subYears(new Date(), 1), new Date()],
        },
        {
          label: 'Last 2 years',
          range: [subYears(new Date(), 2), new Date()],
        },
        /*{
          label: 'All time',
          range: [subYears(new Date(), 100), new Date()],
        }*/
      ];

      let timeFrom = startOfDay(subDays(new Date(), 30));
      let timeTo = endOfDay(new Date());

      if (this.$route.query) {
        if (this.$route.query.fromTime) {
          timeFrom = parseISO(this.$route.query.fromTime);
        }

        if (this.$route.query.toTime) {
          timeTo = parseISO(this.$route.query.toTime);
        }
      }

      if (this.dataPointTimeMinimum) {
        //timeFrom = max([timeFrom, this.dataPointTimeMinimum]);
        //timeTo = max([timeTo, this.dataPointTimeMinimum]);

        for (const datePresetRange of datePresetRanges) {
          //datePresetRange.range[0] = max([datePresetRange.range[0], this.dataPointTimeMinimum]);
          //datePresetRange.range[1] = max([datePresetRange.range[1], this.dataPointTimeMinimum]);
        }
      }

      if (this.dataPointTimeMaximum) {
        //timeFrom = min([timeFrom, this.dataPointTimeMaximum]);
        //timeTo = min([timeTo, this.dataPointTimeMaximum]);

        for (const datePresetRange of datePresetRanges) {
          //datePresetRange.range[0] = min([datePresetRange.range[0], this.dataPointTimeMaximum]);
          //datePresetRange.range[1] = min([datePresetRange.range[1], this.dataPointTimeMaximum]);
        }
      }

      this.dateRange = [timeFrom, timeTo];
      this.datePresetRanges = datePresetRanges;

      this.timeRange = [formatISO(timeFrom), formatISO(timeTo)];
    },
    updateUrlQuery() {
      const query = {};

      if (this.allTime) {
        query.allTime = 1;
      } else if (this.queryTimeRange) {
        query.fromTime = this.queryTimeRange[0];
        query.toTime = this.queryTimeRange[1];
      }

      console.log('updating query to', query);

      this.$router.push({path: this.$route.fullPath, query: query });
    },
    onDateChanged() {
      this.timeRange = [
        formatISO(startOfDay(this.dateRange[0])),
        formatISO(endOfDay(this.dateRange[1]))
      ];

      if (this.dateRange && this.dateRange.length) {
        this.queryTimeRange = [
          format(parseISO(this.timeRange[0]), 'yyyy-MM-dd'),
          format(parseISO(this.timeRange[1]), 'yyyy-MM-dd'),
        ];
      } else {
        this.dateRange = [
          startOfDay(subDays(new Date(), 30)),
          endOfDay(new Date()),
        ];

        this.queryTimeRange = null;
      }

      this.updateUrlQuery();
    },
    retryLoading() {
      this.getEventDataPoints();
    },
    getEventDataPoints() {
      this.loading = true;

      const eventDataPointPromises = [];

      for (const favourite of this.favourites) {
        const promiseDataPoint = this.$apiClient.events.getEventDataPoint(favourite.eventName, favourite.dataPointName).then((dataPoint) => {
          return {
            eventName: favourite.eventName,
            dataPoint: dataPoint,
          };
        });

        eventDataPointPromises.push(promiseDataPoint);
      }

      Promise.allSettled(eventDataPointPromises).then((dataPointResults) => {
        const dataPoints = [];

        for (const dataPointResult of dataPointResults) {
          if (dataPointResult.status === 'fulfilled') {
            const dataPoint = structuredClone(dataPointResult.value.dataPoint);

            dataPoint.event_name = dataPointResult.value.eventName;

            dataPoints.push(dataPoint)
          }
        }

        console.log('dataPoints', dataPoints);

        this.dataPoints = dataPoints;

        this.recalculateDateRange();

        this.loading = false;
      });
    }
  },
  mounted() {
    this.getEventDataPoints();
  }
}
</script>

<style lang="scss">
.event {
  text-align: left;
}

.error {
  color: #aa0000;
  font-weight: bold;
}

.all-time-checkbox {
  line-height: 40px;
}
</style>
