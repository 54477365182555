<template>
  <div class="data-chart">
    <div class="canvas-container">
      <canvas :ref="canvasRef" :key="canvasRef"/>
    </div>
  </div>
</template>

<script>
import {Chart} from "chart.js/auto";
import {formatLabel} from "@/utils/labelFormatter";
import 'chartjs-adapter-date-fns';
import { parse } from 'date-fns';
import {ColourPaletteColours, ColourPalette, getColourPaletteByName} from "./colourPalettes";
import autocolors from 'chartjs-plugin-autocolors';

export default {
  name: 'data-chart',
  props: {
    dataPoint: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartDataType: {
      type: String,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    displayType: {
      type: String,
      required: true,
    },
    groupLabels: {
      type: Array,
      required: false,
      default: [],
    },
    labelFormat: {
      type: String,
      required: false,
      default: null,
    },
    dateGroupBy: {
      type: String,
      required: false,
      default: 'date',
    },
    precision: {
      type: Number,
      required: false,
      default: -1,
    },
    colourPalette: {
      type: String,
      required: false,
      default: null,
    },
    colourPaletteType: {
      type: String,
      required: false,
      default: null,
    },
    colourPaletteUsePattern: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    chartData() {
      this.renderChartData();
    },
    colourPalette() {
      this.renderChartData();
    },
    colourPaletteType() {
      this.renderChartData();
    },
    colourPaletteUsePattern() {
      this.renderChartData();
    },
  },
  data() {
    return {
      chart: null,
      canvasRef: 'canvas0',
      canvasRefCounter: 1,
    };
  },
  computed: {
    displayLabelsWithoutData() {
      return true;
    },
  },
  mounted() {
    this.renderChartData();
  },
  emits: ['renderedColourPalette'],
  methods: {
    renderChartDataSingleValue() {
      const chartData = this.chartData;

      const data = [];
      const labels = [];

      const chartDataKeys = Object.keys(chartData);

      for (const key of chartDataKeys) {
        labels.push(key);

        const hasChartData = !!chartData[key];

        if (hasChartData) {
          data.push(chartData[key]);
        } else if (this.displayLabelsWithoutData) {
          data.push(0);
        }
      }

      const datasets = [
        {
          label: `${this.dataPoint.label}`,
          data: data,
          fill: true,
          //borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
      ];

      this.renderChart('bar', labels, datasets);
    },
    getNormalizedChartData() {
      const chartData = this.chartData;
      const chartDataKeys = Object.keys(chartData);

      const groupLabelStrings = [];

      for (const groupLabel of this.groupLabels) {
        groupLabelStrings.push(groupLabel.toString());
      }

      const chartDataNormalized = {};

      for (let i = 0, len = chartDataKeys.length; i < len; i++) {
        const chartDataKey = chartDataKeys[i];

        const chartDataSingle = chartData[chartDataKey];
        const chartDataSingleKeys = Object.keys(chartDataSingle);

        let chartDataSingleNormalized = {};

        if (chartDataSingle) {
          for (const groupLabelString of groupLabelStrings) {
            chartDataSingleNormalized[groupLabelString] = chartDataSingleKeys.includes(groupLabelString)
              ? chartDataSingle[groupLabelString]
              : null;
          }
        } else if (chartDataSingle === false || chartDataSingle === null || chartDataSingle === undefined) {
          // It's empty, let's just push it back in the same state
          chartDataSingleNormalized = chartDataSingle;
        } else {
          // It's falsy, but another unknown value - let's just set it to an empty object
          chartDataSingleNormalized = {};
        }

        chartDataNormalized[chartDataKey] = chartDataSingleNormalized;
      }

      console.log('chartDataNormalizing', {
        chartData,
        chartDataNormalized
      });

      return chartDataNormalized;
    },
    renderChartDataGroupValues() {
      const chartData = this.getNormalizedChartData();

      const labels = [];

      const datasetsAssoc = {};

      const chartDataKeys = Object.keys(chartData);

      for (const key of chartDataKeys) {
        const hasChartData = chartData[key] && Object.values(chartData[key]).length > 0;

        if (hasChartData || this.displayLabelsWithoutData) {
          labels.push(key);
        }

        if (hasChartData) {
          const dataPointGroupKeys = Object.keys(chartData[key]);

          for (const dataPointGroupKey of dataPointGroupKeys) {
            if (!datasetsAssoc.hasOwnProperty(dataPointGroupKey)) {
              datasetsAssoc[dataPointGroupKey] = [];
            }

            datasetsAssoc[dataPointGroupKey].push(chartData[key][dataPointGroupKey]);
          }
        } else if (this.displayLabelsWithoutData) {
          for (const groupLabel of this.groupLabels) {
            if (!datasetsAssoc.hasOwnProperty(groupLabel)) {
              datasetsAssoc[groupLabel] = [];
            }

            datasetsAssoc[groupLabel].push(null);
          }
        }
      }

      const datasets = [];

      console.log('this.labelFormat', this.labelFormat);

      for (const key in datasetsAssoc) {
        datasets.push({
          label: formatLabel(this.labelFormat, key),
          data: datasetsAssoc[key],
          tension: 0.1
        });
      }

      this.renderChart('bar', labels, datasets);
    },
    renderChartDataStats() {
      const chartData = this.chartData;

      const labels = [];
      const dataSum = [];
      const dataMin = [];
      const dataAverage = [];
      const dataMax = [];

      const chartDataKeys = Object.keys(chartData);

      for (const key of chartDataKeys) {
        const hasChartData = !!chartData[key];

        if (hasChartData || this.displayLabelsWithoutData) {
          labels.push(key);
        }

        if (hasChartData) {
          dataSum.push(chartData[key].sum);
          dataMin.push(chartData[key].min);
          dataAverage.push(chartData[key].average);
          dataMax.push(chartData[key].max);
        } else if (this.displayLabelsWithoutData) {
          dataSum.push(null);
          dataMin.push(null);
          dataAverage.push(null);
          dataMax.push(null);
        }
      }

      const showStatistics = [
        'max',
        'avg',
        'min',
      ]

      const datasets = [];

      if (showStatistics.includes('min')) {
        datasets.push({
          label: `${this.dataPoint.label} (min)`,
          data: dataMin,
          fill: true,
          //borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    console.log('context', context);
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(context.parsed.y);
                    }

                    return label;
                  }
                }
              }
            }
          }
        });
      }

      if (showStatistics.includes('avg')) {
        datasets.push({
          label: `${this.dataPoint.label} (average)`,
          data: dataAverage,
          fill: true,
          tension: 0.1
        });
      }

      if (showStatistics.includes('max')) {
        datasets.push({
          label: `${this.dataPoint.label} (max)`,
          data: dataMax,
          fill: true,
          tension: 0.1
        });
      }

      this.renderChart('line', labels, datasets);
    },
    renderChartData() {
      if (!this.chartData) {
        return;
      }

      if (this.displayType === 'singleValue') {
        this.renderChartDataSingleValue();
      } else if (this.displayType === 'groupValues') {
        this.renderChartDataGroupValues();
      } else {
        this.renderChartDataStats();
      }
    },
    getISOWeek(w, y = new Date().getFullYear()) {
      // @see https://stackoverflow.com/a/71336659
      let d = new Date(y, 0, 4);

      d.setDate(d.getDate() - (d.getDay() || 7) + 1 + 7*(w - 1));

      return d;
    },
    renderChart(chartType, labels, datasets) {
      if (this.colourPalette) {
        const colourPaletteColours = getColourPaletteByName(
          this.colourPalette,
          this.colourPaletteType,
          this.colourPaletteUsePattern
        );

        for (let i = 0, len = datasets.length; i < len; i++) {
          const dataset = datasets[i];

          if (!dataset.backgroundColor) {
            dataset.backgroundColor = colourPaletteColours[i % colourPaletteColours.length];
          }
        }

        this.$emit('renderedColourPalette', colourPaletteColours);
      } else {
        this.$emit('renderedColourPalette', []);
      }

      this.canvasRef = 'canvas' + this.canvasRefCounter;
      this.canvasRefCounter++;

      this.$nextTick(() => {
        const ctx = this.$refs[this.canvasRef];

        let timeOptions = {
          unit: 'day',
          displayFormats: {
            day: 'yyyy-MM-dd',
            week: '\'Week\' II, yyyy',
            month: 'LLL, yyyy',
            year: 'yyyy',
          },
          tooltipFormat: 'yyyy-MM-dd',
          isoWeekday: true,
        };

        if (this.dateGroupBy === 'date') {
          timeOptions.unit = 'day';
        } else if (this.dateGroupBy === 'week') {
          timeOptions.unit = 'week';

          timeOptions.parser = (val) => {
            const valSplit = val.split('-');

            return this.getISOWeek(
              parseInt(valSplit[1], 10),
              parseInt(valSplit[0], 10)
            );
          };
        } else if (this.dateGroupBy === 'month') {
          timeOptions.unit = 'month';
        } else if (this.dateGroupBy === 'year') {
          timeOptions.unit = 'year';
        }

        timeOptions.tooltipFormat = timeOptions.displayFormats[timeOptions.unit];

        const chartOptions = {
          type: chartType,
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            //events: [],
            scales: {
              y: {
                beginAtZero: true
              },
              x: {
                type: 'time',
                time: timeOptions,
              }
            },
          },
          plugins: [
            autocolors,
          ]
        };

        if (this.precision >= 0) {
          chartOptions.options.scale = {
            ticks: {
              precision: this.precision,
            },
          };
        }

        console.log('chartOptions', chartOptions);

        this.chart = new Chart(ctx, chartOptions);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.canvas-container {
  width: 100%;
  height: 350px;
  padding: 15px;
}
</style>
