<template>
  <div class="dashboard">
    <div class="d-flex">
      <div class="flex-column flex-shrink-0 p-3 bg-light sidebar" >
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <span class="fs-4">SeriousAnalytics</span>
        </a>
        <hr>
        <ul class="list-unstyled ps-0">
          <li class="mb-1">
            <router-link to="/dashboard" class="btn align-items-center rounded collapsed" aria-expanded="true">
              Home
            </router-link>
          </li>
          <li class="mb-1">
            <h3>Events</h3>
            <div>
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="loadingEvents">
                  Loading events..
                </li>
                <li v-else-if="eventNames.length <= 0">(no events)</li>
                <li v-else v-for="event in eventNames">
                  <router-link :to="'/dashboard/events/' + event" class="link-dark rounded">{{ event }}</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1">
            <h3>Timers</h3>
            <div>
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="loadingTimers">
                  Loading timers..
                </li>
                <li v-else-if="timerNames.length <= 0">(no timers)</li>
                <li v-else v-for="timer in timerNames">
                  <router-link :to="'/dashboard/timers/' + timer" class="link-dark rounded">{{ timer }}</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1">
            <h3>Surveys</h3>
            <div>
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="loadingSurveys">
                  Loading surveys..
                </li>
                <li v-else-if="surveyNames.length <= 0">(no surveys)</li>
                <li v-else v-for="survey in surveyNames">
                  <router-link :to="'/dashboard/surveys/' + survey" class="link-dark rounded">{{ survey }}</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="mb-1" v-if="showFavourites">
            <h3>Favourites</h3>
            <div>
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/dashboard/favourites" class="link-dark rounded">
                    Favourites ({{ favouriteCount }})
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="sidebar-bottom">
          <hr>
          <div class="dropdown">
            <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2"
               data-bs-toggle="dropdown" aria-expanded="false">
              <strong>{{ username }}</strong>
            </a>
            <ul class="dropdown-menu text-small shadow">
              <li><a class="dropdown-item" @click.prevent="signOut" href="#">Sign out</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex-column flex-grow-1 p-3 bg-white text-dark dashboard-sub-page-container">
        <div class="row" v-if="page === 'timer' || page === 'event' || page === 'survey'">
          <dashboard-abstract-event-view
            :key="eventName"
            :event-name="eventName"
            :event-type="eventType"
          />
        </div>
        <div class="row" v-else-if="page === 'favourites'">
          <dashboard-favourite-view />
        </div>
        <div class="row" v-else>
          <h1>Welcome to the SeriousAnalytics dashboard</h1>
          <p>Use the menu on the left to select the events you want to see</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import DashboardAbstractEventView from './dashboard/010-AbstractEventView.vue';
import DashboardFavouriteView from './dashboard/020-FavouriteView.vue';
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
  name: 'DashboardView',
  data() {
    return {
      eventNames: [],
      timerNames: [],
      surveyNames: [],

      loadingEvents: true,
      loadingTimers: true,
      loadingSurveys: true,

      page: 'dashboard',

      eventName: null,
    }
  },
  components: {
    DashboardAbstractEventView,
    DashboardFavouriteView,
    NoDragImage,
  },
  watch: {
    '$router.currentRoute.value': {
      handler(value) {
        this.onRouterMetaChange(value);
      },
      deep: true,
    }
  },
  mounted() {
    this.$apiClient.events.getEvents('event').then((events) => {
      const eventNames = [];

      for (const event of events) {
        eventNames.push(event.event);
      }

      this.eventNames = eventNames;
      this.loadingEvents = false;
    })

    this.$apiClient.events.getEvents('timer').then((timers) => {
      const timerNames = [];

      for (const timer of timers) {
        timerNames.push(timer.event);
      }

      this.timerNames = timerNames;
      this.loadingTimers = false;
    })

    this.$apiClient.events.getEvents('survey').then((surveys) => {
      const surveyNames = [];

      for (const survey of surveys) {
        surveyNames.push(survey.event);
      }

      this.surveyNames = surveyNames;
      this.loadingSurveys = false;
    })

    this.onRouterMetaChange(this.$router.currentRoute.value)
  },
  methods: {
    onRouterMetaChange(route) {
      this.page = route.meta.page;

      this.eventName = route.params.eventName || null;
    },
    signOut() {
      this.$apiClient.setBearerToken(null);

      this.$router.push('/');
    }
  },
  computed: {
    ...mapState(['username', 'favourites']),
    showFavourites() {
      return this.favouriteCount > 0;
    },
    eventType() {
      if (this.page === 'event') {
        return 'Event';
      } else if (this.page === 'timer') {
        return 'Timer';
      } else if (this.page === 'Survey') {
        return 'Survey';
      }

      return null;
    },
    favouriteCount() {
      return this.favourites.length;
    },
  }
}
</script>

<style lang="scss" scoped>
ul {
  text-align: left;
}

.btn-toggle {
  &[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);

    &::before {
      color: red !important;
      transform: rotate(90deg);
    }
  }

  &::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }
}

.btn-toggle-nav {
  margin-left: 30px;
}

.dashboard {
  height: 100%;

  > .d-flex {
    height: 100%;
  }
}

.sidebar {
  width: 280px;
  border-right: 1px solid #bababa;
  position: relative;

  h3 {
    margin-top: 15px;
    padding-left: 15px;
  }
}

.sidebar-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
}

.dashboard-sub-page-container {
  overflow-y: scroll;
  height: 100%;
  padding: 0;
}
</style>
