<template>
  <h1>AppButtons</h1>

  <dev-settings-panel anchor="bottomRight">
    <div class="row">
      <label class="form-label">Color</label>
      <bootstrap-select v-model="color" :options="options.color" />
    </div>
    <div class="row mt-4">
      <label class="form-label">Size</label>
      <bootstrap-select v-model="size" :options="options.size" />
    </div>
    <div class="row mt-4">
      <label class="form-label">Position</label>
      <bootstrap-select v-model="position" :options="options.position" />
    </div>
    <div class="row mt-4">
      <label class="form-label">Disabled</label>
      <bootstrap-select v-model="disabled" :options="options.disabled" />
    </div>
  </dev-settings-panel>

  <div class="output-panel row mt-5">
    <div class="container">
      <div class="row">
        <app-button
          :disabled="disabled"
          :position="position"
          :color="color"
          :size="size"
        >
          {{ buttonText }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '../AppButton.vue'
import { POSITION_RELATIVE, POSITION_FIXED, COLOR_GREEN, COLOR_YELLOW, SIZE_MEDIUM, SIZE_SMALL } from '../AppButton.vue';
import {DevComponentContainer, DevPanel, EventTicks} from "@seriousgamesinteractive/components-vue3/DevComponent";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";

export default {
  name: 'AppButtonDev',
  components: {DevPanel, BootstrapSelect, AppButton, EventTicks },
  extends: DevComponentContainer,
  data() {
    return {
      disabled: false,

      position: POSITION_RELATIVE,
      color: COLOR_GREEN,
      size: SIZE_SMALL,

      buttonText: 'My Text',

      options: {
        disabled: [
          {
            value: false,
            label: 'FALSE',
          },
          {
            value: true,
            label: 'TRUE',
          },
        ],
        position: [
          {
            value: POSITION_RELATIVE,
            label: POSITION_RELATIVE,
          },
          {
            value: POSITION_FIXED,
            label: POSITION_FIXED,
          },
        ],
        color: [
          {
            value: COLOR_GREEN,
            label: COLOR_GREEN,
          },
          {
            value: COLOR_YELLOW,
            label: COLOR_YELLOW,
          },
        ],
        size: [
          {
            value: SIZE_SMALL,
            label: SIZE_SMALL,
          },
          {
            value: SIZE_MEDIUM,
            label: SIZE_MEDIUM,
          },
        ],
      }
    };
  },
};
</script>
