export function localStorageGetFavouritesKey(username) {
  return `favourites_${username}`;
}

export function localStorageSetFavourites(username, favourites) {
  localStorage.setItem(localStorageGetFavouritesKey(username), JSON.stringify(favourites || []));
}

export function localStorageGetFavourites(username) {
  const favourites = localStorage.getItem(localStorageGetFavouritesKey(username));

  return favourites ? JSON.parse(favourites) : [];
}
