import * as chroma from 'chroma-js';
import * as pattern from "patternomaly";
import { getRandomShape } from 'patternomaly/src/shapes/index';

const ColourPalette = {
  SummerWarmth: 'summerWarmth',
  SpringPastels: 'springPastels',
  RiverNights: 'riverNights',
  DutchField: 'dutchField',
  RetroMetro: 'retroMetro',

  BeachsideBoardwalk: 'beachsideBoardwalk', // Bright, sunny, with blues of the sea and oranges of a sunny beach.
  DeepOceanToSunset: 'deepOceanToSunset', // Dark oceanic tones through to warm sunset colors.
  SpicedWine: 'spicedWine', // Rich, deep reds and purples with a pop of orange zest.
  ForestFloor: 'forestFloor', // Earthy tones that evoke the colors of the forest ground and autumn foliage.
  SerenitySkies: 'serenitySkies', // Calming blues that you might find in the sky just after dawn or before dusk.

  Aurora: 'aurora',
};

const ColourPaletteColours = {
  [ColourPalette.SummerWarmth]: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
  [ColourPalette.SpringPastels]: ['#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7', '#a3d5f7', '#f7a3a3', '#f7e4a3', '#a3f7b5', '#d5a3f7', '#a3f7e4', '#f7d9a3', '#a3a6f7', '#f7a3f1', '#c4f0c2', '#fce1a1', '#a1fcea', '#fcb5a1', '#a1a0fc', '#fca1fc', '#a1fcf7', '#f1fca1', '#a1fcb5',],
  [ColourPalette.RiverNights]: ['#b30000', '#7c1158', '#4421af', '#1a53ff', '#0d88e6', '#00b7c7', '#5ad45a', '#8be04e', '#ebdc78', '#acacac', '#8d8d8d', '#6e6e6e', '#4f4f4f', '#303030', '#111111', '#000b5e', '#000862', '#000866'],
  [ColourPalette.DutchField]: ['#e60049', '#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#b3d4ff', '#00bfa0', '#ace600', '#49ff0b', '#91e650', '#d8e600', '#f5199b', '#ff8300', '#b40adc', '#ffd4b3', '#00bf72'],
  [ColourPalette.RetroMetro]: ['#ea5545', '#f46a9b', '#ef9b20', '#edbf33', '#ede15b', '#bdcf32', '#87bc45', '#27aeef', '#b33dc6', '#e50000', '#f4a9bb', '#ef6b20', '#eda233', '#eded5b', '#bdff32', '#87ff45', '#27ffef', '#b3a3c6'],
  [ColourPalette.BeachsideBoardwalk]: ['#8ecae6', '#219ebc', '#023047', '#ffb703', '#fb8500',],
  [ColourPalette.DeepOceanToSunset]: ['#005f73', '#001219', '#0a9396', '#94d2bd', '#e9d8a6', '#ee9b00', '#ca6702', '#bb3e03', '#ae2012', '#9b2226',],
  [ColourPalette.SpicedWine]: ['#5f0f40', '#9a031e', '#fb8b24', '#e36414', '#0f4c5c',],
  [ColourPalette.ForestFloor]: ['#582f0e', '#7f4f24', '#936639', '#a68a64', '#b6ad90', '#c2c5aa', '#a4ac86', '#656d4a', '#414833', '#333d29',],
  [ColourPalette.SerenitySkies]: ['#012a4a', '#013a63', '#01497c', '#014f86', '#2a6f97', '#2c7da0', '#468faf', '#61a5c2', '#89c2d9', '#a9d6e5',],
  [ColourPalette.Aurora]: ['#dc443c', '#8b3d46', '#dfdb80', '#5e3957', '#303a5f', '#f98241', '#435f76', '#f1f2e3', '#64767e', '#332d4a', '#374f6d', '#473960', '#23233b', '#913429', '#262344', '#b79b7c', '#4f6a84', '#a4a484', '#e56560', '#b33a3d', '#f1613c', '#843743', '#5f3354', '#dfc28c', '#f5413b', '#8a897c', '#fbb03a', '#34426c', '#568876', '#d7433c', '#7a3b5c', '#b5a784', '#66323b', '#9f4c40', '#e18216', '#d4ad1a', '#b98e59', '#fce44c', '#bc5c44', '#1c3454',],
};

const ColourPaletteType = {
  Default: 'default',
  Linear: 'linear',
  Varied: 'varied',
}

function getContrastingColor(color) {
  let hsl = chroma(color).hsl();
  // Invert the hue by adding 180 (mod 360 to ensure it's within the 0-360 range)
  hsl[0] = (hsl[0] + 180) % 360;
  // You could also invert the lightness, but that can lead to very unsaturated colors:
  // hsl[2] = 1 - hsl[2];
  return chroma.hsl(hsl);
}

function getRgbaStringFromRgbaArray(rgbaColor, overrideAlpha = null) {
  return `rgba(${rgbaColor[0]}, ${rgbaColor[1]}, ${rgbaColor[2]}, ${overrideAlpha !== null ? overrideAlpha : rgbaColor[3]})`;
}

/**
 * THIS Is A COPY OF pattern.generate, except it also accepts a patternOpacity
 *
 * @param colorList
 * @param patternOpacity
 * @returns {*}
 */
function generatePattern(colorList, patternOpacity = .5) {
  let firstShapeType;
  let previousShapeType;

  return colorList.map((color, index, list) => {
    let shapeType;

    if (index === 0) {
      shapeType = getRandomShape();
      previousShapeType = shapeType;
      firstShapeType = previousShapeType;
    } else if (index === list.length - 1) {
      shapeType = getRandomShape([previousShapeType, firstShapeType]);
    } else {
      shapeType = getRandomShape([previousShapeType]);
      previousShapeType = shapeType;
    }

    const chromaColor = chroma(color);

    const luminance = chromaColor.luminance();
    const patternColor = luminance <= .5 ? [255, 255, 255] : [0, 0, 0];
    //const patternColor = getContrastingColor(color).alpha(patternOpacity).rgba();

    const patternColorString = getRgbaStringFromRgbaArray(patternColor, .3);
    const colorRgba = getRgbaStringFromRgbaArray(chromaColor.rgba(), .75);

    return pattern.draw(shapeType, colorRgba, patternColorString, 20);
  });
}

function getColourPaletteByName(colourPaletteName, type, usePattern = false) {
  if (!ColourPaletteColours[colourPaletteName]) {
    throw new Error(`Could not find colour palette by name "${colourPaletteName}"`);
  }

  let patternColours = ColourPaletteColours[colourPaletteName];

  if (type === ColourPaletteType.Linear) {
    patternColours = generateLinearPalette(patternColours);
  } else if (type === ColourPaletteType.Varied) {
    patternColours = generateVariedPalette(patternColours);
  }

  if (usePattern) {
    patternColours = generatePattern(patternColours);
  }

  return patternColours;
}

function generatePatternPalette(colours) {
  const patterns = [
    'plus',
    'cross',
    'dash',
    'cross-dash',
    'dot',
    'dot-dash',
    'disc',
    'ring',
    'line',
    'line-vertical',
    'weave',
    'zigzag',
    'zigzag-vertical',
    'diagonal',
    'diagonal-right-left',
    'square',
    'box',
    'triangle',
    'triangle-inverted',
    'diamond',
    'diamond-box',
  ];

  const patternLength = patterns.length;

  const patternColours = [];

  for (let i = 0, len = colours.length; i < len; i++) {
    const rgba = chroma(colours[i]).alpha(.5).rgba();
    const rgbaString = `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;

    patternColours.push(pattern.draw(patterns[i % patternLength], rgbaString));
  }

  return patternColours;
}

function findDistinctColors(colors, numColors) {
  // Convert colors to LAB for better perceptual distance calculations
  let labColors = colors.map(color => chroma(color).lab());

  // Calculate the distance between each pair of colors
  let distances = [];
  for (let i = 0; i < labColors.length; i++) {
    for (let j = i + 1; j < labColors.length; j++) {
      let distance = chroma.distance(labColors[i], labColors[j], 'lab');
      distances.push({pair: [i, j], distance});
    }
  }

  // Sort distances
  distances.sort((a, b) => b.distance - a.distance);

  // Extract the most distinct colors
  let distinctIndices = new Set();
  let count = 0;
  for (let i = 0; i < distances.length && count < numColors; i++) {
    if (!distinctIndices.has(distances[i].pair[0])) {
      distinctIndices.add(distances[i].pair[0]);
      count++;
    }
    if (!distinctIndices.has(distances[i].pair[1])) {
      distinctIndices.add(distances[i].pair[1]);
      count++;
    }
  }

  // Map indices back to colors
  return Array.from(distinctIndices).map(index => colors[index]);
}

function generateLinearPalette(baseColors) {
  const scale = chroma.scale(baseColors).mode('lab').colors(30);

  return scale;
}

function generateVariedPalette(baseColors) {
  const scale = chroma.scale(baseColors).mode('lab').colors(30);
  const indexes = splitRange(0, scale.length - 1, 6);

  const colours = [];

  for (const index of indexes) {
    colours.push(scale[index]);
  }

  return colours;
}

function splitRange(start, end, count) {
  const returnValues = [start, end];

  const half = Math.floor(end - start) / 2;

  let diff = half;

  for (let i = 0; i < count; i++) {
    const offsetStart = start + diff;
    const offsetEnd = end - diff;

    for (let j = offsetStart; j <= offsetEnd; j += diff) {
      const value = j < half ? Math.floor(j) : Math.ceil(j);

      if (!returnValues.includes(value)) {
        returnValues.push(value);
      }
    }

    diff /= 2;
  }

  return returnValues;
}

export { ColourPalette, ColourPaletteColours, ColourPaletteType, getColourPaletteByName };
