<template>
  <div class="result-bar-container">
    <div class="result-bar" :style="style">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultBar',
  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    widthPercent() {
      return (this.value / this.total) * 100;
    },
    label() {
      const roundPercent = Math.round(this.widthPercent);
      const total = Math.round(this.total * (this.widthPercent / 100));

      return `${roundPercent}% (${total} total)`;
    },
    style() {
      return {
        width: `${ this.widthPercent }%`,
      };
    },
  },
};
</script>

<style lang="scss">
.result-bar-container {
  border: 1px solid black;
  border-radius: 5px;
  overflow: hidden;
}

.result-bar {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  color: black;
  background: #90c6ec;
  display: inline-block;
}

</style>
