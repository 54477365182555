import {createRouter, createWebHistory} from 'vue-router'

import DevView from '../views/000-DevView.vue'
import LoginView from '../views/010-LoginView.vue'
import DashboardView from '../views/020-DashboardView.vue'

import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      requireLogin: false,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: 'favourites',
        name: 'favourites',
        component: DashboardView,
        meta: {
          requireLogin: true,
          page: 'favourites',
        },
      },
      {
        path: 'events',
        name: 'events',
        meta: {
          requireLogin: true,
          page: 'events',
        },
        children: [
          {
            path: ':eventName',
            name: 'event',
            component: DashboardView,
            meta: {
              requireLogin: true,
              page: 'event',
            },
          }
        ]
      },
      {
        path: 'timers',
        name: 'timers',
        meta: {
          requireLogin: true,
          page: 'timers',
        },
        children: [
          {
            path: ':eventName',
            name: 'timer',
            component: DashboardView,
            meta: {
              requireLogin: true,
              page: 'timer',
            },
          }
        ]
      },
      {
        path: 'surveys',
        name: 'surveys',
        meta: {
          requireLogin: true,
          page: 'surveys',
        },
        children: [
          {
            path: ':eventName',
            name: 'survey',
            component: DashboardView,
            meta: {
              requireLogin: true,
              page: 'survey',
            },
          }
        ]
      }
    ]
  },
]

const isDev = true;

if (isDev) {
  routes.push({
    path: '/dev',
    name: 'DevMain',
    component: DevView,
    children: [
      {
        path: ':component',
        name: 'DevSub',
        component: DevView,
      },
    ],
  });
}

const router = createRouter({
  history: createWebHistory('/dashboard/'),
  routes
})

router.beforeEach((to, from) => {
  const meta = to.meta || {};

  const requireLogin = !!meta.requireLogin;

  if (requireLogin) {
    const sessionToken = store.getters.sessionToken || null;

    if (!sessionToken) {
      store.commit('loginRedirectUrl', to.fullPath);

      router.push('/');

      return false;
    }
  }

  return true;
});

export default router
