<template>
  <div>
    <div v-for="colourPalette in colourPalettes">
      <h2 class="text-start">{{ colourPalette }}</h2>
      <colour-palette :name="colourPalette" :use-pattern="false" />
      <colour-palette :name="colourPalette" :use-pattern="true" />
      <colour-palette :name="colourPalette" type="linear" :use-pattern="false" />
      <colour-palette :name="colourPalette" type="linear" :use-pattern="true" />
      <colour-palette :name="colourPalette" type="varied" :use-pattern="false" />
      <colour-palette :name="colourPalette" type="varied" :use-pattern="true" />
    </div>
  </div>
</template>
<script>
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
  DevComponentContainer,
  DevSettingsPanel,
  EventTicks,
  EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import ColourPaletteComponent from "@/components/chart/colourPalette/ColourPalette.vue";
import { ColourPalette } from '@/components/chart/colourPalettes';

export default {
  name: 'ColourPalettesDev',
  components: {
    DevSettingsPanel,
    EventTicks,
    'colour-palette': ColourPaletteComponent,
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],
  data() {
    return {
      colourPalettes: ColourPalette,
    };
  },
}
</script>
