<template>
  <button class="favourite-toggle" @click="toggle" :title="computedTitle" :class="'favourite-toggle-' + (modelValue ? 'on' : 'off')">
    <span class="star-on" />
  </button>
</template>

<script>
export default {
  name: 'FavouriteToggle',
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
  computed: {
    computedTitle() {
      if (this.modelValue) {
        return 'Remove favourite';
      } else {
        return 'Add favourite';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$buttonSize: 31px;

.favourite-toggle {
  width: $buttonSize;
  height: $buttonSize;
  outline: 0;
  background: url("@/assets/icons/favourite-off.png");
  background-size: 100%;
  position: relative;
  border: 0;
  padding: 0;
  margin: 0;

  &:hover {
    .star-on {
      opacity: .75;
    }
  }

  &.favourite-toggle-on {
    .star-on {
      opacity: 1;
    }
  }

  .star-on {
    opacity: 0;
    width: $buttonSize;
    height: $buttonSize;
    position: absolute;
    top: 0;
    left: 0;
    background: url("@/assets/icons/favourite-on.png");
    background-size: 100%;
    transition: 200ms ease-in-out;
  }
}
</style>
