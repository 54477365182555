<template>
  <button
    :class="buttonClasses"
    class="app-button"
    :disabled="disabled"
    @click.prevent.stop="onClickButton"
  >
    <span class="background"></span>
    <span class="content">
      <slot/>
    </span>
  </button>
</template>
<script>
export const POSITION_RELATIVE = 'relative';
export const POSITION_FIXED = 'fixed';

export const COLOR_GREEN = 'green';
export const COLOR_YELLOW = 'yellow';
export const COLOR_RED = 'red';
export const COLOR_PURPLE = 'purple';

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';

export default {
  name: 'AppButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: POSITION_RELATIVE,
      required: false,
    },
    color: {
      type: String,
      default: COLOR_GREEN,
      required: false,
    },
    size: {
      type: String,
      default: SIZE_MEDIUM,
      required: false,
    },
  },
  emits: ['click'],
  computed: {
    buttonClasses() {
      const buttonClasses = [
        `app-button-color-${this.color || COLOR_GREEN}`,
        `app-button-position-${this.position || POSITION_RELATIVE}`,
        `app-button-size-${this.size || SIZE_MEDIUM}`,
      ];

      if (this.disabled) {
        buttonClasses.push('app-button-disabled');
      }

      return buttonClasses;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
$buttonHeight: 40px;
$borderRadius: 10px;

$colorGreenBg: #8BC152;
$colorGreenText: #ffffff;
$colorGreenBgShadow: #395F11;

$colorYellowBg: #F5BA41;
$colorYellowText: #ffffff;
$colorYellowBgShadow: #9F5610;

$colorRedBg: #CF4949;
$colorRedText: #ffffff;
$colorRedBgShadow: darken($colorRedBg, 22%);

$colorPurpleBg: #9579DC;
$colorPurpleText: #ffffff;
$colorPurpleBgShadow: darken($colorPurpleBg, 22%);

@mixin appButtonColors($name, $backgroundColor, $textColor, $shadowColor) {
  &.app-button-color-#{$name} {
    background-color: $backgroundColor;
    color: $textColor;

    &.app-button-position-relative {
      &:hover {
        .content {
          background-color: lighten($backgroundColor, 5%);
        }

        .background {
          top: 7px;
          background-color: lighten($shadowColor, 5%);
        }
      }

      &:active {
        .content {
          background-color: lighten($backgroundColor, 5%);
        }

        .content {
          box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
        }

        .background {
          top: 5px;
          background-color: lighten($shadowColor, 5%);
        }
      }
    }

    .content {
      background-color: $backgroundColor;

      line-height: $buttonHeight;
    }

    .background {
      background-color: $shadowColor;
    }

    &:hover {
      .background {
        top: 15px;
      }
    }
  }
}

.app-button {
  height: $buttonHeight;
  line-height: 100%; // $buttonHeight does not look correct on firefox android
  cursor: pointer;
  outline: 0;
  border: 0;
  font-weight: 700;
  min-width: 250px;
  max-width: 100%;

  // Top glow
  box-shadow: 0px -4px 10px 1px #C9FF8F;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  width: 100%;
  text-align: center;

  &.app-button-disabled {
    opacity: .8;
    cursor: not-allowed;
    box-shadow: none;

    .background {
      display: none;
    }
  }

  &:focus {
    outline: 0;
    border: 0;
  }

  transition: bottom 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    height 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    color 200ms ease-in-out,
    background-color 200ms ease-in-out,
    box-shadow 200ms ease-in-out;

  // Colors
  @include appButtonColors('green', $colorGreenBg, $colorGreenText, $colorGreenBgShadow);
  @include appButtonColors('yellow', $colorYellowBg, $colorYellowText, $colorYellowBgShadow);
  @include appButtonColors('red', $colorRedBg, $colorRedText, $colorRedBgShadow);
  @include appButtonColors('purple', $colorPurpleBg, $colorPurpleText, $colorPurpleBgShadow);

  // Sizes
  &.app-button-size-small {
    font-size: 12pt;
  }

  &.app-button-size-medium {
    font-size: 16pt;
  }

  &.app-button-size-block {
    font-size: 12pt;
    width: 100% !important;
    max-width: auto !important;
  }

  // Positions
  &.app-button-position-relative {
    position: relative;
    border-radius: $borderRadius;
    padding: 5px 10px;
    max-width: 500px;
    width: auto;
  }

  &.app-button-position-fixed {
    padding: 0;
    position: fixed;
    bottom: -$buttonHeight;
    left: 0;
    border-radius: $borderRadius $borderRadius 0 0;

    z-index: 100000;
  }

  > span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .content, .background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $borderRadius;
  }

  .background {
    top: 6px;

    transition: top 100ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
      background-color 100ms ease-in-out;
  }
}
</style>
