<template>
  <router-view/>
</template>

<script>
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
  name: 'App',
  components: {
    NoDragImage,
  },
}
</script>

<style lang="scss">
@import "@/sass/variables.scss";
@import "@/sass/animations.scss";

html, body {
  height: 100%;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100%;
}
</style>
