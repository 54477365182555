export default {
  state: {
    loginRedirectUrl: null,
  },
  mutations: {
    loginRedirectUrl: (state, value) => {
      state.loginRedirectUrl = value;
    },
  },
  getters: {
    loginRedirectUrl(state) {
      return state.loginRedirectUrl;
    },
  },
}
