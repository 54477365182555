<template>
    <div>
        <div class="dev-panel anchor-bottomLeft">
            <div class="container">
                <div class="row">
                    <button class="btn btn-success btn-sm" @click="showDevPanel = !showDevPanel">
                        {{ showDevPanel ? 'Close panel' : 'Open panel' }}
                    </button>
                </div>
                <div v-if="showDevPanel" class="row mt-4 mb-4">
                    <label class="form-label">Component</label>
                    <select v-model="selectedDevComponent" class="form-select">
                        <option value="">
                            Select a dev component
                        </option>
                        <option v-for="devComponentName in devComponentNames" :key="devComponentName"
                                :value="devComponentName"
                        >
                            {{ devComponentName }}
                        </option>
                    </select>
                </div>
                <div :class="'show-dev-panel-' + (showDevPanel ? 'on' : 'off')" class="row">
                    <div id="dev-settings-panel" ref="settings-panel-container" class="container mb-4">

                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="pt-2">
            Loading..
        </div>
        <div v-else :class="'component-theme-' + computedTheme" class="row component-container">
            <div class="dev-component-container">
                <component :is="selectedDevComponent" v-if="selectedDevComponent" :theme="computedTheme"/>
                <div v-else>
                    Select a Dev component in the dropdown above
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButtonDev from '@/components/common/dev/AppButtonDev.vue'
import DataChartDev from "@/components/chart/dev/DataChartDev.vue";
import ColourPalettesDev from "@/components/chart/colourPalette/ColourPalettesDev.vue";

const devComponents = {
    AppButtonDev,
    DataChartDev,
  ColourPalettesDev,
}

export default {
    name: 'DevView',
    components: devComponents,
    data() {
        return {
            devComponentNames: Object.keys(devComponents),
            selectedDevComponent: '',
            selectedTheme: 'grey',
            loading: false,

            showDevPanel: false,
        }
    },
    computed: {
        currentDevComponent() {
            return this.$router.currentRoute?.value?.params?.component
        },
        computedTheme() {
            return this.selectedTheme;
        }
    },
    watch: {
        currentDevComponent() {
            if (this.currentDevComponent === this.selectedDevComponent) {
                return
            }

            this.selectedDevComponent = this.currentDevComponent || ''
        },
        selectedDevComponent() {
            if (this.currentDevComponent === this.selectedDevComponent) {
                return
            }

            console.log('Push router', `/dev/${this.selectedDevComponent}`)
            this.$router.push(`/dev/${this.selectedDevComponent}`)
        },
    },
    mounted() {
        this.selectedDevComponent = this.currentDevComponent || ''
    },
}
</script>

<style lang="scss" scoped>
.component-container {
    border: 1px solid #333333;
    padding: 10px;
}

.dev-panel {
    position: absolute;
    z-index: 99999999999;

    background: white;
    padding: 10px;
    border-right: 5px solid black;
    border-top: 5px solid black;
    border-top-right-radius: 15px;

    &.anchor-bottomLeft {
        bottom: 0;
        left: 0;
    }

    &.anchor-bottomRight {
        bottom: 0;
        right: 0;
    }

    &.anchor-topLeft {
        top: 0;
        left: 0;
    }

    &.anchor-topRight {
        top: 0;
        right: 0;
    }
}
</style>

<style lang="scss">
.dev-panel {
    color: black;
    text-align: left;
}

.show-dev-panel-off {
    height: 0;
    overflow-y: hidden;
}

.show-dev-panel-on {
    height: fit-content;
}

.dev-component-container {
  padding: 30px;
}
</style>
