import {addGetParamsToUrl} from "@seriousgamesinteractive/api-client-vue3";

export default {
  getEvents(type) {
    let url = `/event/events`;

    url = addGetParamsToUrl(url, { eventType: type });

    return this.get(url).then((data) => {
      return data.events;
    });
  },
  getEvent(eventName) {
    return this.get(`/event/events/${eventName}`).then((data) => {
      return data;
    });
  },
  getEventStats(eventName, data = {}) {
    return this.get(`/event/events/${eventName}/stats`, data).then((data) => {
      return data;
    });
  },
  getEventDataPoints(eventName) {
    return this.get(`/event/events/${eventName}/dataPoints`).then((data) => {
      return data.dataPoints;
    });
  },
  getEventDataPoint(eventName, dataPointName) {
    return this.get(`/event/events/${eventName}/dataPoints/${dataPointName}`).then((data) => {
      return data.dataPoint;
    });
  },
  getEventDataPointData(eventName, dataPointName, data = {}) {
    return this.get(`/event/events/${eventName}/dataPoints/${dataPointName}/data`, data).then((data) => {
      return {
        dataPoints: data.dataPoints || [],
        dataPointType: data.dataPointType,
        dataFilters: data.dataFilters || [],
        groupLabels: data.groupLabels || [],
        dateGroupBy: data.dateGroupBy,
      };
    });
  },
};
