<template>
  <div class="login">
    <div>
      <form class="bg-light border-dark">
        <div class="row g-3" v-if="error">
          <div class="col-4">
            <label></label>
          </div>
          <div class="col">
            <p class="error"><strong>{{ error }}</strong></p>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-4">
            <label>Username</label>
          </div>
          <div class="col">
            <input class="form-control" v-model="username" type="text"/>
          </div>
        </div>
        <div class="row g-3 mt-0">
          <div class="col-4">
            <label>Password</label>
          </div>
          <div class="col">
            <input class="form-control" v-model="password" type="password"/>
          </div>
        </div>
        <div class="row g-3 mt-0">
          <div class="col-12">
            <button type="submit" class="btn btn-success" @click.prevent="login" :disabled="loggingIn">Log in</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'LoginView',
  components: {},
  data() {
    return {
      username: process.env.VUE_APP_DASHBOARD_PREFILL_USERNAME || '',
      password: process.env.VUE_APP_DASHBOARD_PREFILL_PASSWORD || '',
      error: null,
      loggingIn: false,
    }
  },
  computed: {
    ...mapState(['loginRedirectUrl'])
  },
  methods: {
    login() {
      const username = this.username;

      this.error = null;

      this.loggingIn = true;

      this.$apiClient.auth.login(username, this.password).then((response) => {
        this.$store.commit('username', username);
        this.$store.commit('sessionToken', response.sessionToken);

        this.$apiClient.setBearerToken(response.sessionToken);

        this.$router.push(this.loginRedirectUrl || '/dashboard');
      }).catch((error) => {
        this.error = error.toString().replace(/^Error:\s+/, '');

        this.loggingIn = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

form {
  width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;

  label {
    position: relative;
    top: 7px;
  }

  input, .btn {
    width: 100% !important;
  }
}

.error {
  color: #aa0000;
  font-weight: bold;
}
</style>
