<template>
  <div class="colour-palette">
    <label><strong>{{ name }}</strong> / {{ type || 'default' }} / {{ usePattern ? 'with pattern' : 'without pattern' }}</label>
    <div class="colour-blocks d-flex align-items-stretch">
      <div
        class="colour-block flex-fill"
        v-for="colourPaletteColour in colourPaletteColours"
        :style="{ 'background': colourPaletteColour }"
      />
    </div>
  </div>
</template>
<script>
import {ColourPaletteColours, getColourPaletteByName} from "@/components/chart/colourPalettes";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    usePattern: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    name() {
      return this.generatePalette();
    },
    type() {
      return this.generatePalette();
    },
    usePattern() {
      return this.generatePalette();
    },
    renderIndex() {
      return this.generatePalette();
    },
  },
  data() {
    return {
      colourPaletteColours: [],
    }
  },
  mounted() {
    this.generatePalette();
  },
  methods: {
    generatePalette() {
      const colourPaletteColours = getColourPaletteByName(this.name, this.type, this.usePattern);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the width and height
      canvas.width = 100;  // Pattern width
      canvas.height = 100; // Pattern height

      const colourPaletteColoursTranslated = [];

      for (const colourPaletteColour of colourPaletteColours) {
        if (colourPaletteColour instanceof CanvasPattern) {
          ctx.fillStyle = colourPaletteColour;
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          colourPaletteColoursTranslated.push(`url(${canvas.toDataURL()}`);
        } else {
          colourPaletteColoursTranslated.push(colourPaletteColour);
        }
      }

      this.colourPaletteColours = colourPaletteColoursTranslated;
    },
  },
}
</script>

<style lang="scss" scoped>
.colour-palette {
  text-align: left;
  border-radius: 5px;
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  page-break-inside: avoid;
}

.colour-blocks {
  border: 1px solid #000000;
  height: 120px;
  width: 100%;

  .colour-block {
    position: relative;

    .colour-block-color {
      background: rgba(255, 255, 255, .3);
      padding: 3px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
