<template>
  <div>
    <data-chart
      :chart-data="chartData"
      :chart-data-type="chartDataType"
      :chart-type="chartType"
      :data-point="dataPoint"
      :date-group-by="dateGroupBy"
      :display-type="displayType"
      :group-labels="groupLabels"
      :label-format="labelFormat"
      :colour-palette="colourPalette"
      :colour-palette-type="colourPaletteType"
      :colour-palette-use-pattern="colourPaletteUsePattern"
      @rendered-colour-palette="onRenderedColourPalette"
    />

    <div class="colour-blocks d-flex align-items-stretch">
      <div
        class="colour-block flex-fill"
        v-for="colourPaletteColour in colourPaletteColours"
        :style="{ 'background': colourPaletteColour }"
      />
    </div>
  </div>

  <dev-settings-panel anchor="bottomRight">
    <div>
      <label>Colour palette</label>
      <bootstrap-select :options="colourPaletteOptions" v-model="colourPalette" :placeholder-value="null" />
    </div>

    <div>
      <label>Colour palette type</label>
      <bootstrap-select :options="colourPaletteTypeOptions" v-model="colourPaletteType" :placeholder-value="null" />
    </div>

    <div>
      <label>Use pattern</label>
      <bootstrap-select :options="colourPaletteUsePatternOptions" v-model="colourPaletteUsePattern" :placeholder-value="false" />
    </div>
  </dev-settings-panel>
</template>
<script>
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
  DevComponentContainer,
  DevSettingsPanel,
  EventTicks,
  EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import DataChart from "@/components/chart/DataChart.vue";
import {ColourPalette, ColourPaletteColours, ColourPaletteType} from "../colourPalettes";

export default {
  name: 'DataChartDev',
  components: {
    DataChart,
    BootstrapSelect,
    DevSettingsPanel,
    EventTicks,
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    const colourPaletteOptions = [
      {
        label: 'Auto',
        value: null,
      }
    ];

    for (const key in ColourPalette) {
      colourPaletteOptions.push({
        label: key,
        value: ColourPalette[key],
      });
    }

    const chartData = {};

    const padNumber = (num) => {
      return num < 10 ? '0' + num : num.toString();
    };

    const groupLabels = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

    for (let i = 1; i <= 12; i++) {
      chartData[`2023-${padNumber(i)}`] = this.generateChartData(groupLabels);
    }

    return {
      colourPalette: null,
      colourPaletteOptions: colourPaletteOptions,

      colourPaletteTypeOptions: [
        {
          label: 'Default',
          value: null,
        },
        {
          label: 'Linear',
          value: ColourPaletteType.Linear,
        },
        {
          label: 'Varied',
          value: ColourPaletteType.Varied,
        },
      ],

      colourPaletteUsePatternOptions: [
        {
          label: 'No',
          value: false,
        },
        {
          label: 'Yes',
          value: true,
        }
      ],

      colourPaletteUsePattern: false,
      colourPaletteType: null,

      chartData: chartData,
      chartDataType: 'filter',
      chartType: 'auto',
      dataPoint: {
        "id": 229,
        "data_point_name": "dilemmaId",
        "label": "Dilemma ID",
        "type": "filter",
        "data_type": "integer",
        "group_by": "date",
        "range_min": -1,
        "range_max": 10,
        "format": "Dilemma {value}",
        "statistic": "average,min,max",
        "chart_type": "auto"
      },
      dateGroupBy: 'month',
      displayType: 'groupValues',
      groupLabels: groupLabels,
      labelFormat: 'Dilemma {value}',

      colourPaletteColours: [],
    }
  },
  methods: {
    onRenderedColourPalette(colourPaletteColours) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the width and height
      canvas.width = 100;  // Pattern width
      canvas.height = 100; // Pattern height


      const colourPaletteColoursTranslated = [];

      for (const colourPaletteColour of colourPaletteColours) {
        if (colourPaletteColour instanceof CanvasPattern) {
          ctx.fillStyle = colourPaletteColour;
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          colourPaletteColoursTranslated.push(`url(${canvas.toDataURL()}`);
        } else {
          colourPaletteColoursTranslated.push(colourPaletteColour);
        }
      }

      console.log('colourPaletteColoursTranslated', colourPaletteColoursTranslated);

      this.colourPaletteColours = colourPaletteColoursTranslated;
    },
    generateChartData(groupLabels) {
      // Decide whether the object should be empty
      if (Math.random() > 0.9) { // 10% chance to be empty
        return {};
      }

      // Decide the number of keys to include
      const numberOfKeys = Math.floor(Math.random() * 10) + 1; // Up to 10 keys

      // Shuffle the array and slice it to get random keys
      const shuffledKeys = groupLabels.sort(() => 0.5 - Math.random()).slice(0, numberOfKeys);

      // Generate the data for the selected keys
      const chartDataForDate = {};

      shuffledKeys.forEach(key => {
        // Assign a random value between 1 and 10 to each key
        chartDataForDate[key] = Math.floor(Math.random() * 10) + 1;
      });

      return chartDataForDate;
    }
  }
}
</script>
